body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  padding: env(safe-area-inset);
  background-size: cover;
}
.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: aliceblue;
}

.generator-field{
overflow-wrap: break-word;
}

.button-generator-field{
  width: 90dvh;
  max-width: 90%;
margin-bottom: 30px;
background-color: transparent;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: aliceblue;
  font-size: calc(10px + 2vmin);
  padding: 10px;
}

.bigOFF{
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: aliceblue;
  transition-duration: 0.4s;
  font-size: 90%;
  width: 60px;
  height: 60px;
  margin: 10px;
  text-align: center;
}
.bigON{
  background-color: aliceblue;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: black;
  transition-duration: 0.4s;
  font-size: 90%;
  width: 60px;
  height: 60px;
  margin: 10px;
  text-align: center;
}

.smallOFF{
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: aliceblue;
  transition-duration: 0.4s;
  font-size: 90%;
  width: 60px;
  height: 60px;
  margin: 10px;
  text-align: center;
}
.smallON{
  background-color: aliceblue;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: black;
  transition-duration: 0.4s;
  font-size: 90%;
  width: 60px;
  height: 60px;
  margin: 10px;
  text-align: center;
}

.numOFF{
  background-color: transparent;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: aliceblue;
  transition-duration: 0.4s;
  font-size: 80%;
  width: 80px;
  height: 60px;
  margin: 10px;
  text-align: center;
}
.numON{
  background-color: aliceblue;
  border-radius: 15px;
  border: 2px solid aliceblue;
  color: black;
  transition-duration: 0.4s;
  font-size: 80%;
  width: 80px;
  height: 60px;
  margin: 10px;
  text-align: center;
}

.button{
  background-color: transparent;
  border-radius: 25px;
  border: 2px solid aliceblue;
  color: aliceblue;
  transition-duration: 0.4s;
  font-size: 90%;
  width: 200px;
  height: 70px;
  margin: 20px;
}

.button:hover {
  background-color: aliceblue;
  color: black;
  transition: 0.5;
}

.button:disabled {
  background-color: transparent;
  color:gray;
  border-color: gray;
}

.slider{
  color: aliceblue;
}




